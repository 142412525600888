import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PropTypes from "prop-types";

const SToTop = ({ parentRef }) => {
  const [visible, setVisible] = useState(true);

  const scrollToTop = () => {
    parentRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (parentRef.current.scrollTop > 50) {
        //console.log("parentRef.current.scrollY: ", parentRef.current.scrollTop);
        setVisible(true);
      } else {
        //console.log("parentRef.current.scrollY: ", parentRef.current.scrollTop);
        setVisible(false);
      }
    };
    if (parentRef.current) {
      //console.log("父组件的 DOM 元素:", parentRef.current);
      parentRef.current.addEventListener("scroll", toggleVisibility);
    }
  }, [parentRef]);

  return (
    <div>
      {visible && (
        <Fab
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "130px",
            right: "30px",
            width: "40px",
            height: "40px",
            border: "1px solid #0cf",
          }}
          color="primary"
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </div>
  );
};

SToTop.propTypes = {
  parentRef: PropTypes.object,
};

export default SToTop;
