import { useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import PropTypes from "prop-types";

const IRPlayer = (props) => {
  const playerRef = useRef(null);
  const changePlaybackRate = (rate) => {
    if (playerRef.current) {
      const audio = playerRef.current.audio.current;
      audio.playbackRate = rate;
    }
  };

  return (
    <div className="player-container">
      <AudioPlayer
        ref={playerRef}
        src={props.url}
        onPlay={(e) => console.log("onPlay")}
        footer={
          <div className="player-footer">
            <button onClick={() => changePlaybackRate(0.5)}>0.5x</button>
            <button onClick={() => changePlaybackRate(1)}>1x</button>
            <button onClick={() => changePlaybackRate(1.5)}>1.5x</button>
            <button onClick={() => changePlaybackRate(2)}>2x</button>
          </div>
        }
      />
    </div>
  );
};

export default IRPlayer;

IRPlayer.propTypes = {
  url: PropTypes.string,
};
