import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Copyright from "../internals/components/Copyright";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

function md5Hex(string) {
  return CryptoJS.MD5(string).toString(CryptoJS.enc.Hex);
}

export default function PanelAccount() {
  const [pwd1, setPwd1] = React.useState("");
  const [pwd2, setPwd2] = React.useState("");

  const validateInputs = () => {
    if (pwd1 !== pwd2) {
      alert("Passwords do not match");
    } else {
      // send request to server
      fetch("/api/changepwd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("usertoken"),
        },
        body: JSON.stringify({
          password: md5Hex(pwd1),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 0) {
            alert("Password changed successfully!");
          } else {
            alert("Failed to change password: " + data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "600px" } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Change my password
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            required
            fullWidth
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="new-password"
            variant="outlined"
            value={pwd1}
            onChange={(e) => setPwd1(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Repeat Password</FormLabel>
          <TextField
            required
            fullWidth
            name="password2"
            placeholder="••••••"
            type="password"
            id="password2"
            autoComplete="new-password"
            variant="outlined"
            value={pwd2}
            onChange={(e) => setPwd2(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={validateInputs}
        >
          Submit
        </Button>
        <Copyright sx={{ my: 4 }} />
      </Box>
    </Box>
  );
}
