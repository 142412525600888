import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs, { breadcrumbsClasses } from "@mui/material/Breadcrumbs";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import Box from "@mui/material/Box";
import { MyContext } from "./MyContext";
import { useNavigate } from "react-router-dom";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: "center",
  },
}));

export default function NavbarBreadcrumbs() {
  const { curTitle, setCurTitle } = React.useContext(MyContext);
  const navigate = useNavigate();
  return (
    <Box>
      {curTitle === "Home" ? (
        <StyledBreadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextRoundedIcon fontSize="small" />}
        >
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontWeight: 600 }}
          >
            Home
          </Typography>
        </StyledBreadcrumbs>
      ) : (
        <StyledBreadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextRoundedIcon fontSize="small" />}
        >
          <Typography
            variant="body1"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setCurTitle("Home");
              navigate("/");
            }}
          >
            Home
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "text.primary", fontWeight: 600 }}
          >
            {curTitle !== "" ? curTitle : "Book"}
          </Typography>
        </StyledBreadcrumbs>
      )}
    </Box>
  );
}
