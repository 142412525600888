import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import IntroSection from "./intro";
import LoadingIndicator from "../../components/LoadingIndicator";
//import Button from "@mui/material/Button";
//import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import "./index.css";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, IconButton, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
//import HomeIcon from "@mui/icons-material/Home";
//import SearchIcon from "@mui/icons-material/Search";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import AppTheme from "../shared-theme/AppTheme";
import DarkButton from "./darkbutton";
import CheckIcon from "@mui/icons-material/Check";
//import PlayCircleIcon from "@mui/icons-material/PlayCircle";
//import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import Cookies from "js-cookie";
import { useRef } from "react";
import LocalStorageWithExpiry from "../../utils/storage.js";
import IRPlayer from "./player";
import SToTop from "./stotop";
import Rating from "@mui/material/Rating";

const storage = new LocalStorageWithExpiry();

/*
const theme = createTheme({
  colorSchemes: {
    dark: true,
  },
});*/

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

/*
<ListItemButton onClick={() => gotoHome()}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" sx={{ marginLeft: "10px" }} />
                  </ListItemButton>
*/

const PageReader = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const navigate = useNavigate();
  var [bookData, setBookData] = useState(null);
  const parentRef = useRef(null);
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);

  var gid = 1;
  var did = 1;
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedSetting, setExpandedSetting] = React.useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isFinish, setIsFinish] = useState(false);
  //const audioRef = useRef(null);
  //const [isPlaying, setIsPlaying] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDrawer2 = (newOpen) => () => {
    console.log("ToggleDrawer:", newOpen);
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer2(false)}>
      <List>
        {bookData === null ? (
          <Box>
            <LoadingIndicator />
          </Box>
        ) : (
          bookData.Chapters.map((item, index) => (
            <ListItem key={item.Title} disablePadding>
              <ListItemButton onClick={() => scrollToText(item.Title)}>
                <ListItemText primary={item.Title} />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );

  const { bookId, mode } = useParams();
  useEffect(() => {
    fetch("/api/getbookdata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ID: bookId, Mode: mode }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0) {
          if (data.data.Introduction !== "" && mode === "keyideas") {
            data.data.Chapters.unshift({
              Title: "Introduction",
              Content: data.data.Introduction,
            });
          }
          setBookData(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });

    var myFinishedIDList = storage.getWithExpiry("finishedIDList");
    if (myFinishedIDList) {
      var jsonmySavedIDList = JSON.parse(myFinishedIDList);
      if (jsonmySavedIDList.includes(bookId)) {
        setIsFinish(true);
      }
    }
  }, [bookId, mode]);

  const handleListItemClick = (event, index) => {
    console.log("Index:", index);
    if (index === 0) {
      setExpanded(!expanded);
      setExpandedSetting(false);
    }
    if (index === 1) {
      setExpanded(false);
      setExpandedSetting(!expandedSetting);
    }
  };

  const scrollToText = (textId) => {
    const element = document.getElementById(textId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  /*const gotoForyou = () => {
    navigate("/foryou");
  };*/

  const gotoHome = () => {
    navigate("/");
  };

  /*const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };*/

  /*const handleCanPlay = () => {
    // 如果 URL 上有 audio 参数，就播放音频
    const urlParams = new URLSearchParams(window.location.search);
    const audio = urlParams.get("audio");
    if (audio === "1" && !isPlaying && audioRef.current !== null) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };*/

  const onClickFinish = () => {
    if (Cookies.get("usertoken") === undefined) {
      alert("Please login first.");
      return;
    }
    setIsFinish(true);
    fetch("/api/add2finish", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("usertoken"),
      },
      body: JSON.stringify({ ID: bookId }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  };

  const onRatingChange = (event, newValue) => {
    if (Cookies.get("usertoken") === undefined) {
      alert("Please login first.");
      setValue(0);
      setHover(-1);
      return;
    }
    setValue(newValue);
    fetch("/api/ratebook", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("usertoken"),
      },
      body: JSON.stringify({ BookID: bookId, Rating: newValue }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        alert("Rating submitted OK.");
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
        setValue(0);
        setHover(-1);
        alert("Rating Failed.");
      });
  };

  const onRatingHover = (event, newHover) => {
    setHover(newHover);
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Drawer open={open} onClose={toggleDrawer2(false)}>
        {DrawerList}
      </Drawer>
      <Box className="WholePage">
        {bookData === null ? (
          <Box>
            <LoadingIndicator />
          </Box>
        ) : (
          <Box className="BookReader">
            <Box
              className="ReaderLeftPanel"
              sx={{
                display: { xs: "none", sm: "flex" },
                minWidth: isSidebarOpen ? "250px" : "50px",
              }}
            ></Box>
            <Box
              className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                {isSidebarOpen && (
                  <img
                    src="/banner.png"
                    alt="logo"
                    className="ReaderLogo"
                    onClick={gotoHome}
                  />
                )}

                {isSidebarOpen && (
                  <IconButton
                    onClick={toggleSidebar}
                    color="primary"
                    sx={{
                      width: "30px",
                      height: "30px",
                      marginTop: "10px",
                      border: "0px",
                    }}
                  >
                    <FirstPageIcon />
                  </IconButton>
                )}

                {!isSidebarOpen && (
                  <IconButton
                    onClick={toggleSidebar}
                    color="primary"
                    sx={{
                      width: "30px",
                      height: "30px",
                      marginTop: "10px",
                      marginLeft: "210px",
                      border: "0px",
                    }}
                  >
                    <LastPageIcon />
                  </IconButton>
                )}
              </Box>
              {isSidebarOpen && (
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  sx={{ marginLeft: "20px", marginRight: "20px" }}
                >
                  <ListItemButton
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemIcon>
                      <LightbulbIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Chapters"
                      sx={{ marginLeft: "10px" }}
                    />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {expanded
                    ? bookData.Chapters.map((item, index) => (
                      <ListItem key={did++} disablePadding>
                        <ListItemButton
                          onClick={() => scrollToText(item.Title)}
                        >
                          <ListItemText
                            primary={
                              mode === "keyideas"
                                ? did === 1
                                  ? item.Title
                                  : did - 1 + "." + item.Title
                                : item.Title
                            }
                            primaryTypographyProps={{
                              style: { whiteSpace: "normal" }, // 允许换行
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))
                    : null}
                  <ListItemButton
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Settings"
                      sx={{ marginLeft: "10px" }}
                    />
                    {expandedSetting ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {expandedSetting && <DarkButton />}
                </List>
              )}

              {isSidebarOpen && (
                <List
                  component="nav"
                  aria-label="main items folders"
                  sx={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "100px",
                  }}
                ></List>
              )}
            </Box>
            <Box
              ref={parentRef}
              className="ReaderRightPanel"
              sx={{ marginLeft: "20px" }}
            >
              <SToTop parentRef={parentRef} />
              <Box className="FileTitle">{bookData.Title}</Box>
              {bookData.Chapters.map((item) => (
                <Box key={gid++} id={item.Title}>
                  <Box className="BookTitle">
                    {mode === "keyideas"
                      ? gid === 1
                        ? item.Title
                        : gid - 1 + "." + item.Title
                      : item.Title}
                  </Box>
                  <Typography
                    style={{
                      whiteSpace: "pre-line",
                      marginTop: "20px",
                      marginRight: "20px",
                      marginBottom: "40px",
                      lineHeight: "29px",
                      fontSize: "18px",
                      fontFamily: "ff-tisa-web-pro",
                    }}
                  >
                    {item.Content}
                  </Typography>
                </Box>
              ))}
              {mode === "details" && (
                <Box
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="simple-uncontrolled"
                    onChange={onRatingChange}
                    onChangeActive={onRatingHover}
                    getLabelText={getLabelText}
                    defaultValue={0}
                    value={value}
                    precision={0.5}
                    size="large"
                  />
                  {value !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              )}
              {mode === "keyideas" && !isFinish && (
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<CheckIcon />}
                  onClick={() => onClickFinish()}
                  sx={{ marginBottom: "20px" }}
                >
                  Mark as finished
                </Button>
              )}
              {mode === "keyideas" && isFinish && (
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<CheckIcon />}
                  sx={{ marginBottom: "20px" }}
                  disabled
                >
                  Finished
                </Button>
              )}

              <IntroSection />
            </Box>
          </Box>
        )}
        {mode === "keyideas" && bookData !== null && bookData.Audio != null && (
          <IRPlayer url={bookData.Audio} />
        )}
      </Box>
    </AppTheme>
  );
};

export default PageReader;
