import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import { GoogleIcon, DiscordIcon, SitemarkIcon } from "./CustomIcons";
import AppTheme from "../shared-theme/AppTheme";
//import ColorModeSelect from "../shared-theme/ColorModeSelect";
//<ColorModeSelect sx={{ position: "fixed", top: "1rem", right: "1rem" }}/>
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function md5Hex(string) {
  return CryptoJS.MD5(string).toString(CryptoJS.enc.Hex);
}

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

export default function PageSignIn(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [openForgot, setOpenForgot] = React.useState(false);
  const [openTip, setOpenTip] = React.useState(false);
  const [tipText, setTipText] = React.useState("");
  const [tipType, setTipType] = React.useState("warning");
  const [starting, setStarting] = React.useState(false);

  // 组件的值
  const [emailValue, setEmailValue] = React.useState("");
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value || ""); // 确保不会变为 undefined
  };

  const navigate = useNavigate();
  const gotoSignup = () => {
    navigate("/signup");
  };

  const GoogleLogin = () => {
    window.open(
      "https://accounts.google.com/o/oauth2/auth?client_id=887450027524-ke91okdr85kpg48j9j6m5l4c3iopqkec.apps.googleusercontent.com&redirect_uri=https://insightreader.ai/login/google&response_type=code&scope=email%20profile",
      "_self"
    );
  };

  const DiscordLogin = () => {
    window.open(
      "https://discord.com/oauth2/authorize?client_id=1305212270292504626&response_type=code&redirect_uri=https%3A%2F%2Finsightreader.ai%2Flogin%2Fdiscord&scope=identify+email",
      "_self"
    );
  };

  const handleClickOpenForgot = () => {
    //console.log("Forgot password clicked");
    setOpenForgot(true);
  };

  const handleCloseForgot = () => {
    //console.log("Forgot password closed");
    setOpenForgot(false);
  };

  const handleTipClose = () => {
    setOpenTip(false);
  };

  const handleSubmit = (event) => {
    if (emailError || passwordError) {
      event.preventDefault();
      return;
    }
    event.preventDefault();
    console.log("Form submitted");
    const password = document.getElementById("password").value;
    Cookies.set("useremail", emailValue);
    setStarting(true);
    fetch("/api/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailValue,
        password: md5Hex(password),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0) {
          setStarting(false);
          window.location.href = "/";
        } else {
          setTipText("Login failed: " + data.message);
          setTipType("error");
          setOpenTip(true);
          setStarting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setTipText("Login failed: " + error);
        setTipType("error");
        setOpenTip(true);
        setStarting(false);
      });
  };

  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={starting}
        onClick={handleTipClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openTip}
        autoHideDuration={6000}
        onClose={handleTipClose}
      >
        <Alert
          onClose={handleTipClose}
          severity={tipType}
          variant="filled"
          sx={{
            width: "100%",
          }}
        >
          {tipText}
        </Alert>
      </Snackbar>
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <SitemarkIcon />
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                name="email"
                placeholder="your@email.com"
                required
                fullWidth
                variant="outlined"
                color={emailError ? "error" : "primary"}
                value={emailValue}
                onChange={handleEmailChange}
                autoComplete="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                required
                fullWidth
                variant="outlined"
                color={passwordError ? "error" : "primary"}
                autoComplete="current-password"
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" checked />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={validateInputs}
            >
              Sign in
            </Button>
            <Link
              component="button"
              type="button"
              onClick={handleClickOpenForgot}
              variant="body2"
              sx={{ alignSelf: "center" }}
            >
              Forgot your password?
            </Link>
            <ForgotPassword open={openForgot} handleClose={handleCloseForgot} />
          </Box>
          <Divider>or</Divider>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => GoogleLogin()}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => DiscordLogin()}
              startIcon={<DiscordIcon />}
            >
              Sign in with Discord
            </Button>
            <Typography sx={{ textAlign: "center" }}>
              Don&apos;t have an account?{" "}
              <Link
                variant="body2"
                sx={{ alignSelf: "center", cursor: "pointer" }}
                onClick={() => gotoSignup()}
              >
                Sign up
              </Link>
            </Typography>
          </Box>
        </Card>
      </SignInContainer>
    </AppTheme>
  );
}
