import { createContext, useState } from "react";
import PropTypes from "prop-types";

const MyContext = createContext();

const MyProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [curTitle, setCurTitle] = useState("Explore");
  const [curBookID, setCurBookID] = useState("");
  const [curCollectionID, setCurCollectionID] = useState("");

  return (
    <MyContext.Provider
      value={{
        isLogin,
        setIsLogin,
        curTitle,
        setCurTitle,
        curBookID,
        setCurBookID,
        curCollectionID,
        setCurCollectionID,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

MyProvider.propTypes = {
  children: PropTypes.node,
};

export { MyContext, MyProvider };
