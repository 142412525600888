import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Copyright from "../internals/components/Copyright.js";
import Loading from "./Loading.js";
import LocalStorageWithExpiry from "../../../utils/storage.js";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Typography,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import Devider from "@mui/material/Divider";
import "./PanelBookInfo.css";
import RecBooks from "./RecBooks.js";
import TrendingList from "./TrendingList.js";
//import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LyricsIcon from "@mui/icons-material/Lyrics";
import IosShareIcon from "@mui/icons-material/IosShare";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
//import { MyContext } from "./MyContext";
//import { useContext } from "react";

const storage = new LocalStorageWithExpiry();

function useBackButtonHandler(callback) {
  useEffect(() => {
    const handleBackButton = (event) => {
      callback(event);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [callback]);
}

// 书籍详情页，通过书籍 ID 获取书籍详情
export default function PanelBookInfo(props) {
  const [bookInfo, setBookInfo] = useState(null); // 书的信息
  const [open, setOpen] = useState(false); // Snackbar 是否打开
  // 用于播放音频
  const audioDescRef = useRef(null);
  const audioAuthorRef = useRef(null);
  const [isDescPlaying, setIsDescPlaying] = useState(false);
  const [isAuthorPlaying, setIsAuthorPlaying] = useState(false);
  // 记录是否已经收藏
  const [isSaved, setIsSaved] = useState(false);
  // 本地记录的收藏书籍列表
  const [savedIDList, setSavedIDList] = useState([]);
  // 这里如果拉取不到数据，就会一直显示 Loading，所以需要一个超时机制，以及错误页
  //const [errorState, setErrorState] = useState(false);
  // const { setCurTitle } = useContext(MyContext);
  useBackButtonHandler((event) => {
    console.log("back button pressed");
    event.preventDefault();
  });

  const handleCopy = () => {
    var textToCopy = "https://insightreader.ai/book/" + props.ID;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const handleSave = () => {
    if (!Cookies.get("usertoken")) {
      alert("Please sign in to save books.");
      return;
    }
    // 添加书籍到自己的收藏中
    setIsSaved(true);
    fetch("/api/add2mylib", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("usertoken"),
      },
      body: JSON.stringify({ ID: props.ID }),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        console.log(jsonData);
        // 更新本地存储
        savedIDList.push(props.ID);
        storage.setWithExpiry(
          "savedIDList",
          JSON.stringify(savedIDList),
          60 * 60 * 24 * 7 * 1000 // 7 days
        );
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
      });
  };

  const toggleDescPlayPause = () => {
    if (isAuthorPlaying) {
      audioAuthorRef.current.pause();
      audioAuthorRef.current.currentTime = 0;
      setIsAuthorPlaying(false);
    }
    if (isDescPlaying) {
      audioDescRef.current.pause();
    } else {
      audioDescRef.current.play();
    }
    setIsDescPlaying(!isDescPlaying);
  };

  const toggleAuthorPlayPause = () => {
    if (isDescPlaying) {
      audioDescRef.current.pause();
      audioDescRef.current.currentTime = 0;
      setIsDescPlaying(false);
    }
    if (isAuthorPlaying) {
      audioAuthorRef.current.pause();
    } else {
      audioAuthorRef.current.play();
    }
    setIsAuthorPlaying(!isAuthorPlaying);
  };

  const handleDescEnded = () => {
    setIsDescPlaying(false);
  };

  const handleAuthorEnded = () => {
    setIsAuthorPlaying(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    var mybook = storage.getWithExpiry("bookInfo" + props.ID);
    if (mybook) {
      var jsonmybook = JSON.parse(mybook);
      console.log("bookinfo", jsonmybook);
      setBookInfo(jsonmybook);
    } else {
      setBookInfo(null);
      fetch("/api/db/bookinfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: props.ID }),
      })
        .then((response) => response.json())
        .then((jsonData) => {
          //jsonData.data.AboutAuthor = jsonData.data.AboutAuthor.replace(
          //  /\n/g,
          //  "<br>"
          //);
          //jsonData.data.Desc = jsonData.data.Desc.replace(/\n/g, "<br>");
          console.log("bookinfo", jsonData.data);
          setBookInfo(jsonData.data);
          storage.setWithExpiry(
            "bookInfo" + props.ID,
            JSON.stringify(jsonData.data),
            60 * 60 * 24 * 1000 // 1 day
          );
          //setCurTitle(jsonData.data.Title);
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    }
    // 加载本地存储的收藏书籍列表
    var mySavedIDList = storage.getWithExpiry("savedIDList");
    if (mySavedIDList) {
      var jsonmySavedIDList = JSON.parse(mySavedIDList);
      setSavedIDList(jsonmySavedIDList);
      if (jsonmySavedIDList.includes(props.ID)) {
        setIsSaved(true);
      }
    }
  }, [props.ID]);

  return (
    <Box
      sx={{
        width: "95%",
        maxWidth: { sm: "95%", md: "1700px" },
        marginLeft: "3%",
        marginTop: "30px",
        marginRight: "3%",
      }}
    >
      {bookInfo === null && <Loading />}
      {bookInfo && (
        <Box className="BookInfoContainer">
          <Box className="BookTwoColumns">
            <Box className="BookInfoLeft">
              <Box className="BookInfoLine1">
                <Box className="BookInfoTitle" sx={{ marginBottom: "20px" }}>
                  <Typography variant="h2">{bookInfo.Title}</Typography>
                </Box>
                <Box className="BookInfoAuthor" sx={{ marginBottom: "20px" }}>
                  <Typography variant="h6">{bookInfo.Author}</Typography>
                </Box>
                <Box className="BookInfoProblem" sx={{ marginBottom: "20px" }}>
                  <Typography>{bookInfo.Problem}</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    {bookInfo.Score !== "" ? (
                      <Grid xs={6} sx={{ marginRight: "20px" }}>
                        <Typography
                          variant="body1"
                          display="flex"
                          alignItems="center"
                        >
                          <StarBorderIcon style={{ marginRight: 8 }} />
                          {bookInfo.Score}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid xs={6}></Grid>
                    )}
                    {bookInfo.KeyIdeas !== -1 ? (
                      <Grid xs={6} sx={{ marginRight: "20px" }}>
                        <Typography
                          variant="body1"
                          display="flex"
                          alignItems="center"
                        >
                          <AvTimerIcon style={{ marginRight: 8 }} />
                          {bookInfo.ReadTime} mins
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid xs={6}></Grid>
                    )}
                    {bookInfo.KeyIdeas !== -1 ? (
                      <Grid xs={6} sx={{ marginRight: "20px" }}>
                        <Typography
                          variant="body1"
                          display="flex"
                          alignItems="center"
                        >
                          <LightbulbIcon style={{ marginRight: 8 }} />
                          {bookInfo.KeyIdeas} Key ideas
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid xs={6}></Grid>
                    )}
                    <Grid xs={6}>
                      <Typography
                        variant="body1"
                        display="flex"
                        alignItems="center"
                      >
                        <LyricsIcon style={{ marginRight: 8 }} />
                        Audio & Text
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Box>

              <Grid
                container
                spacing={2}
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                {bookInfo.KeyIdeas !== -1 ? (
                  <Grid xs={6} sx={{ margin: "5px" }}>
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<MenuBookIcon />}
                      onClick={() => {
                        window.open(
                          "/reader/" + props.ID + "/keyideas",
                          "_self"
                        );
                      }}
                    >
                      Key Ideas
                    </Button>
                  </Grid>
                ) : null}
                <Grid xs={6} sx={{ margin: "5px" }}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<HeadphonesIcon />}
                    onClick={() => {
                      window.open(
                        "/reader/" + props.ID + "/keyideas?audio=1",
                        "_self"
                      );
                    }}
                  >
                    Key Ideas
                  </Button>
                </Grid>
                <Grid xs={6} sx={{ margin: "5px" }}>
                  {bookInfo.HasChapter ? (
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<MenuBookIcon />}
                      onClick={() => {
                        window.open(
                          "/reader/" + props.ID + "/details",
                          "_self"
                        );
                      }}
                    >
                      Summary by Chapter
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
              <Devider />
              <Box
                className="BookInfoLine3"
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                    What's it about?
                  </Typography>
                  <IconButton
                    sx={{ border: "0px", display: "none" }}
                    onClick={toggleDescPlayPause}
                  >
                    {isDescPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
                  </IconButton>
                  <audio
                    ref={audioDescRef}
                    src={bookInfo.AudioDesc}
                    onEnded={handleDescEnded}
                  />
                </Box>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {bookInfo.Desc}
                </Typography>
              </Box>
              <Devider />
              <Box
                className="BookInfoLine4"
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                    About the author
                  </Typography>
                  <IconButton
                    sx={{ border: "0px", display: "none" }}
                    onClick={toggleAuthorPlayPause}
                  >
                    {isAuthorPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
                  </IconButton>
                  <audio
                    ref={audioAuthorRef}
                    src={bookInfo.AudioAuthor}
                    onEnded={handleAuthorEnded}
                  />
                </Box>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {bookInfo.AboutAuthor}
                </Typography>
              </Box>
              <Box className="BookInfoLine5" sx={{ marginBottom: "20px" }}>
                <Devider />
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<IosShareIcon />}
                  sx={{
                    marginRight: "20px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  onClick={handleCopy}
                >
                  Share with friends
                </Button>
                {isSaved ? (
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={<BookmarkIcon />}
                    sx={{
                      marginRight: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    disabled
                  >
                    Saved
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={<BookmarkBorderIcon />}
                    sx={{
                      marginRight: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    onClick={handleSave}
                  >
                    Save to library
                  </Button>
                )}

                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    URL copied to clipboard
                  </Alert>
                </Snackbar>
                <Devider />
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "200px",
              }}
            >
              <img
                src={bookInfo.Photo}
                alt={bookInfo.Title}
                className="BookInfoCover"
              />
            </Box>
          </Box>
          <Box className="BookInfoLine6">
            <Typography variant="h4">Similar Books</Typography>
            <Typography>Related books you might enjoy</Typography>
            <Box
              sx={{
                marginTop: "30px",
                marginLeft: "50px",
                marginBottom: "50px",
                marginRight: "50px",
              }}
            >
              <RecBooks />
            </Box>
          </Box>
          <Box className="BookInfoLine7">
            <Typography variant="h4">Trending Books</Typography>
            <Typography>What's popular right now</Typography>
            <Box
              sx={{
                marginTop: "30px",
                marginLeft: "50px",
                marginBottom: "50px",
                marginRight: "50px",
              }}
            >
              <TrendingList />
            </Box>
          </Box>
        </Box>
      )}
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}

PanelBookInfo.propTypes = {
  ID: PropTypes.string,
};
