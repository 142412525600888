import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./CollItem.css";
import { useContext } from "react";
import { MyContext } from "./MyContext";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const CollItem = (props) => {
  const { setCurTitle, setCurBookID, setCurCollectionID } =
    useContext(MyContext);
  const navigate = useNavigate();
  const handleClick = () => {
    setCurBookID("");
    setCurTitle(props.Title);
    setCurCollectionID(props.ID);
    navigate(`/booklist/${props.ID}`);
  };

  return (
    <Box className="RecContainer">
      <img
        className="RecPhoto"
        src={props.PhotoURL}
        alt="PhotoRec"
        onClick={handleClick}
      />
      <Typography
        component="h2"
        variant="subtitle2"
        gutterBottom
        className="RecBookTitle"
        sx={{ fontWeight: "600" }}
      >
        {props.Title}
      </Typography>
      <Typography className="RecSubtitle" sx={{ color: "text.secondary" }}>
        {" "}
        {props.SubTitle}{" "}
      </Typography>
    </Box>
  );
};

CollItem.propTypes = {
  ID: PropTypes.string,
  Title: PropTypes.string,
  SubTitle: PropTypes.string,
  PhotoURL: PropTypes.string,
};

export default CollItem;
