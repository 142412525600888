import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Copyright from "../internals/components/Copyright";
import BookItem from "./BookItem";
import Loading from "./Loading";
import LocalStorageWithExpiry from "../../../utils/storage.js";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Typography from "@mui/material/Typography";

const storage = new LocalStorageWithExpiry();

// 我收藏的书，目前先使用一个随机列表代替
export default function PanelMyLib() {
  const [book, setBook] = React.useState(null);
  const [finbook, setFinBook] = React.useState(null);

  useEffect(() => {
    var mybook = storage.getWithExpiry("savedbook");
    if (mybook) {
      var jsonmybook = JSON.parse(mybook);
      setBook(jsonmybook);
    } else {
      console.log("no book in storage");
      setBook(null);
    }

    var myfinbook = storage.getWithExpiry("finishbook");
    if (myfinbook) {
      var jsonmyfinbook = JSON.parse(myfinbook);
      setFinBook(jsonmyfinbook);
    } else {
      console.log("no finish book in storage");
      setFinBook(null);
    }

    // 总是去拉取最新的数据
    fetch("/api/getmylib", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("usertoken"),
      },
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.data === null) {
          setBook([]);
        } else {
          setBook(jsonData.data);
          storage.setWithExpiry(
            "savedbook",
            JSON.stringify(jsonData.data),
            60 * 60 * 24 * 7 * 1000 // 7 days
          );
          // 生成本地收藏书籍ID列表
          var bookidlist = [];
          jsonData.data.forEach((item) => {
            bookidlist.push(item.ID);
          });
          storage.setWithExpiry(
            "savedIDList",
            JSON.stringify(bookidlist),
            60 * 60 * 24 * 7 * 1000
          ); // 7 days
        }
      })
      .catch((error) => {
        console.error("Error fetching JSON:", error);
        setBook([]);
      });

    //拉取读完的书
    fetch("/api/getmyfinish", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("usertoken"),
      },
    })
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.data === null) {
          setFinBook([]);
        } else {
          setFinBook(jsonData.data);
          storage.setWithExpiry(
            "finishbook",
            JSON.stringify(jsonData.data),
            60 * 60 * 24 * 7 * 1000 // 7 days
          );
          // 生成本地完成书籍ID列表
          var bookidlist = [];
          jsonData.data.forEach((item) => {
            bookidlist.push(item.ID);
          });
          storage.setWithExpiry(
            "finishedIDList",
            JSON.stringify(bookidlist),
            60 * 60 * 24 * 7 * 1000
          ); // 7 days
        }
      })
      .catch((error) => {
        console.error("Error fetching Finish JSON:", error);
        setFinBook([]);
      });
  }, []);

  return (
    <Box
      sx={{
        width: "95%",
        maxWidth: { sm: "95%", md: "1700px" },
        marginLeft: "30px",
        marginTop: "30px",
        marginRight: "30px",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "30px" }}>
        Saved Books
      </Typography>
      <Grid
        container
        spacing={6}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(5) }}
      >
        {book === null && <Loading />}
        {book &&
          book.map((item, index) => (
            <BookItem
              key={item.ID}
              ID={item.ID}
              PhotoURL={item.Photo}
              Title={item.Title}
              BookCount={item.BookCount}
              Author={item.Author}
            />
          ))}
      </Grid>
      <Typography variant="h4" sx={{ marginBottom: "30px" }}>
        Finished Books
      </Typography>
      <Grid
        container
        spacing={6}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(5) }}
      >
        {finbook === null && <Loading />}
        {finbook &&
          finbook.map((item, index) => (
            <BookItem
              key={item.ID}
              ID={item.ID}
              PhotoURL={item.Photo}
              Title={item.Title}
              BookCount={item.BookCount}
              Author={item.Author}
            />
          ))}
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
