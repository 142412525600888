import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
//import Divider from "@mui/material/Divider";
//import SelectContent from "./SelectContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuContent from "./MenuContent";
//import CardAlert from "./CardAlert";
//<CardAlert />
import OptionsMenu from "./OptionsMenu";
import Cookies from "js-cookie";
import "./SideMenu.css";
import { useContext } from "react";
import { MyContext } from "./MyContext";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

export default function SideMenu(props) {
  const { isLogin, setCurTitle } = useContext(MyContext);
  const navigate = useNavigate();

  const gotoHome = () => {
    setCurTitle("Home");
    navigate("/");
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <img
        src="/banner.png"
        alt="Logo"
        className="mainLogo"
        onClick={() => gotoHome()}
      />
      <MenuContent />
      {isLogin ? (
        <Stack
          direction="row"
          sx={{
            p: 2,
            gap: 1,
            alignItems: "center",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          <Avatar
            sizes="small"
            alt="User"
            src={Cookies.get("useravatar") || "avatar.png"}
            sx={{ width: 36, height: 36 }}
          />
          <Box sx={{ mr: "auto" }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                lineHeight: "16px",
                maxWidth: "130px",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Cookies.get("username")}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                maxWidth: "130px",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {Cookies.get("useremail") || ""}
            </Typography>
          </Box>
          <OptionsMenu />
        </Stack>
      ) : (
        <Box sx={{ height: 10 }} />
      )}
    </Drawer>
  );
}
