import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageAbout from "./v2/Home";
//import PageSignup from "./v2/Signup";
//import PageSignIn from "./v2/Signin";
import PageMain from "./v2/Main";
import PageReader from "./v2/Reader";
import PageJump from "./v2/Jump";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<PageMain />} />
        <Route path="/signin" element={<PageMain />} />
        <Route path="/" element={<PageMain />} />
        <Route path="/jump" element={<PageJump />} />
        <Route path="/reader/:bookId/:mode" element={<PageReader />} />
        <Route path="/about" element={<PageAbout />} />
        <Route path="/book/:bookId" element={<PageMain />} />
        <Route path="/booklist/:booklistId" element={<PageMain />} />
        <Route path="/foryou" element={<PageMain />} />
        <Route path="/mylib" element={<PageMain />} />
        <Route path="/settings" element={<PageMain />} />
        <Route path="/profile" element={<PageMain />} />
        <Route path="/account" element={<PageMain />} />
        <Route path="/search/:keywords" element={<PageMain />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
