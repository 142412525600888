import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IntroSection from "../../components/IntroSection";
import LoadingIndicator from "../../components/LoadingIndicator";
import Cookies from "js-cookie";

const PageJump = () => {
  const navigate = useNavigate();

  useEffect(() => {
    var token = Cookies.get("usertoken");
    var ref = Cookies.get("refID");
    var target = Cookies.get("target");
    if (token !== undefined && ref !== undefined) {
      Cookies.remove("refID");
      navigate("/reader/" + ref);
    } else if (token !== undefined && target !== undefined) {
      Cookies.remove("target");
      navigate("/" + target);
    } else {
      navigate("/");
    }
  });

  return (
    <div className="CenterList">
      <div>
        <LoadingIndicator />
      </div>
      <IntroSection />
    </div>
  );
};

export default PageJump;
