import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer, { drawerClasses } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
//import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";

//import MenuButton from "./MenuButton";
import MenuContent from "./MenuContent";
//import CardAlert from "./CardAlert";
//        <CardAlert />;
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "./MyContext";

function SideMenuMobile({ open, toggleDrawer }) {
  //  const navigate = useNavigate();
  const { isLogin, setIsLogin, setCurTitle, setCurBookID, setCurCollectionID } =
    useContext(MyContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove("usertoken");
    Cookies.remove("username");
    Cookies.remove("useravatar");
    setIsLogin(false);
    setCurBookID("");
    setCurCollectionID("");
    setCurTitle("Explore");
    navigate("/");
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: "none",
          backgroundColor: "background.paper",
        },
      }}
    >
      <Stack
        sx={{
          minWidth: "50vw",
          maxWidth: "70dvw",
          height: "100%",
        }}
      >
        {isLogin && (
          <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
            <Stack
              direction="row"
              sx={{ gap: 1, alignItems: "center", flexGrow: 1, p: 1 }}
            >
              <Avatar
                sizes="small"
                alt="User"
                src={Cookies.get("useravatar") || "avatar.png"}
                sx={{ width: 24, height: 24 }}
              />
              <Typography component="p" variant="h6">
                {Cookies.get("username") || "Username"}
              </Typography>
            </Stack>
          </Stack>
        )}
        {isLogin && <Divider />}

        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent />
          <Divider />
        </Stack>

        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<LogoutRoundedIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

SideMenuMobile.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuMobile;
